* {
  font-family: "Poppins", sans-serif;
}

.global-h1 {
  font-size: 40px;
  font-weight: 800;
}

.global-h2 {
  font-size: 24px;
  font-weight: 700;
}

.global-h4 {
  font-size: 18px;
  font-weight: 500;
}

.global-h5 {
  font-size: 16px;
  font-weight: 400;
}

.global-p {
  font-size: 12px;
  font-weight: 400;
}

.global-h6 {
  font-size: 11px;
  font-weight: 400;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f100;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e6e7ff;
  border-radius: 24px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b9b9d7;
}

/* Primary colors */

.primary-blue {
  color: #557aff;
}

.light-primary-lilac {
  color: #cfbeff;
}

.primary-lilac {
  color: #a484ff;
}

/* Neutral colors */

.light-black {
  color: #4a4a4a;
}

.black {
  color: #171719;
}

.light-purple {
  color: #f4f4ff;
}

.light-gray {
  color: #b9b9d7;
}

.gray {
  color: #5656b9;
  transition: color 0.3s ease;
}

.white {
  color: #ffff;
}

.success,
.warning,
.error,
.poor,
.info,
.critical {
  border-radius: 4px;
  padding: 0 8px;
}

.success {
  background: #9cf28f;
  color: #35a400;
}

.warning {
  background: #fff9bf;
  color: #a28f00;
}

.info {
  background-color: #cfbeff;
  color: #fff;
}

.error {
  background: #ffe6d4;
  color: #b76900;
}
.poor {
  border: none;
  background: #ffe6d4;
  color: #b76900;
}

.critical {
  background: #fdd;
  color: #f23d3d;
}

.global-cont {
  /* padding: 84px 0px 0px 264px; */
  background-color: transparent;
  min-height: 100vh;
  transition: 0.5s;
  /* min-width: fit-content; */
  background-color: #f4f4ff;
}

.background {
  background-blend-mode: exclusion;
  background: #cfbefd;
}

.page-overview {
  padding-left: 236px;
  padding-top: 100px;
  padding-bottom: 100px;
  max-width: 100%;
  display: flex;
  justify-content: flex-start;
  transition: margin-left 0.5s, max-with 0.5s;
  margin-left: 0;
  box-sizing: border-box;
}

.page-overview.shift {
  max-width: 85%;
  margin-left: 150px;
}

.overview-header {
  text-align: start;
  margin-bottom: 25px;
}

.flex-1 {
  display: flex;
  justify-content: space-evenly;
  gap: 32px;
}

.page-mask {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 4;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-mask-2 {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 6;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.cont-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: calc(100% - 70px);
  margin-top: 64px;
  margin-left: 64px;
  box-sizing: border-box;
  transition: margin-left 0.5s, max-width 0.5s;
}

.cont-wrapper.middle {
  margin: auto;
}

.name:hover {
  color: #b9b9d7;
}

.cont-wrapper.shift {
  max-width: 85%;
  margin-left: 150px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.blank-card {
  border-radius: 24px;
  background: var(--neutral-white, #fff);
  box-shadow: 0px 0px 6.8px 0px rgba(145, 107, 255, 0.22);
  padding: 24px;
}

.page-select {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14px;
}

.page-select svg {
  cursor: pointer;
}

.page-select-number-cont {
  display: flex;
  gap: 5px;
}

.page-select-number.active {
  border-radius: 50%;
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  background-color: #5656b9;
  color: #fff;
}
.page-select-number.active:hover {
  border-radius: 50%;
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  background-color: #5656b9;
  color: #fff;
}
.page-select-number {
  color: #5656b9;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  transition: background-color 0.5s ease, color 0.5s ease;
}

.page-select-number-dots {
  cursor: default;
  color: #5656b9;
  border-radius: 50%;
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
}

.page-select-number:hover {
  background-color: #b9b9d7;
  color: #fff;
}

.progress-cont {
  position: fixed;
  z-index: 101;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  box-shadow: 0px 0px 6.8px 0px rgba(145, 107, 255, 0.22);
  padding: 24px;
  border-radius: 24px;
}

.custom-progress-bar .progress-bar {
  background-color: #cfbeff !important;
  /* width: 200px !important; */
  color: #171719 !important;
}

.profile_pic {
  width: 44px;
  height: 44px;
  border-radius: 50%;
}
.help-wrapper {
  width: calc(100% - 256px);
  margin-left: 256px;
  margin-top: 100px;
  margin-bottom: 100px;
  position: fixed;
  display: flex;
  justify-content: end;
  padding-right: 14px;
  z-index: 999;
}

.help-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 14px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  transition: background-color ease 0.5s, color ease 0.5s;
  cursor: pointer;
}

.help-cont:hover {
  background-color: #cfbeff;
  color: #fff;
}

.help-icon {
}

.help-text-cont {
  max-width: 50vw;
  width: fit-content;
  max-height: 90vh;
  display: flex;
  text-align: left;
  flex-direction: column;
  background-color: #fff;
  padding: 24px;
  border-radius: 24px;
  overflow: auto;
}

/* Utility classes */
.spacer {
  flex-grow: 1;
}
.cursor-pointer {
  cursor: pointer;
}

.carrot {
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}

.rotate-180 {
  transform: rotate(180deg);
}

@media (max-width: 1300px) {
  .page-overview {
    padding-left: 7vw;
  }
}
@media (max-width: 500px) {
  .cont-wrapper {
    margin-top: 16px;
    padding: 0 16px;
    max-width: 100%;
    margin-top: 0px;
    margin-left: 0px;
  }
  .overview-header h4 {
    text-align: left;
    margin-bottom: 25px;
  }
  .overview-header h1 {
    text-align: left;
  }
  .page-overview {
    padding-left: 0px;
    padding-top: 100px;
    padding-bottom: 0px;
    max-width: 100%;
    display: flex;
    justify-content: flex-start;
    transition: margin-left 0.5s, max-with 0.5s;
    margin-left: 0;
    box-sizing: border-box;
  }
}
